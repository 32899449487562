<template>
  <div>
    <header class="header">
      <div class="pubCon" :class="lang">
        <nav class="hdNav">
          <ul>
            <li @click.stop="menuClick" class="menupot">
              <img src="/img/menu.png" alt=""  class="menuImg">
              <span>{{$t('route.menu')}}</span>
            </li>
            <li class="logo" @click.stop="imgClick"><img src="/img/logo.png" alt=""  ></li>
            <li class="li-cursior">
              <span @click.stop="changeEn" :class="this.$i18n.locale == 'en'? 'active':''" class="en-mobile">EN</span>
              <span @click.stop="changeEn" :class="this.$i18n.locale == 'en'? 'active':''" class="en-pc">English</span>
              <span style="padding: 0px 7px;">|</span>
              <span @click.stop="changeZn" :class="this.$i18n.locale == 'zh'? 'active':''">中文</span>

            </li>
          </ul>
        </nav>
        <transition name="fade">
          <div class="menu" v-show="menuFlag" v-if="this.$i18n.locale=='zh'">
            <a href="https://www.sohochina.com/" target="_blank">{{$t('header.aHome')}}</a>
            <a href="https://www.sohochinaoffice.com/" target="_blank">{{$t('header.arent')}}</a>
            <a href="https://classic.sohochina.com/news-media" target="_blank">{{$t('header.anews')}}</a>
            <a href="https://ir.sohochina.com?l=cn" target="_blank">{{$t('header.invest')}}</a>
            <a href="https://pp.sohochina.com/" target="_blank">采购</a>
            <a href="https://www.sohowuye.com/" target="_blank">物业</a>
            <a href="https://www.hyatt.com/zh-CN/hotel/china/commune-by-the-great-wall/pekub" target="_blank">{{$t('header.commune')}}</a>
            <a href="https://esg.sohochina.com/" target="_blank">ESG</a>
          </div>
          <div class="menu" v-show="menuFlag" v-if="this.$i18n.locale=='en'">
            <a href="https://www.sohochina.com/eindex.aspx?l=en" target="_blank">{{$t('header.aHome')}}</a>
            <a href="https://www.sohochinaoffice.com/?lang=en" target="_blank">{{$t('header.arent')}}</a>
            <a href="https://classic.sohochina.com/en/news-media" target="_blank">{{$t('header.anews')}}</a>
            <a href="https://ir.sohochina.com/AboutSOHOChina.aspx" target="_blank">{{$t('header.invest')}}</a>
            <a href="https://www.hyatt.com/en-US/hotel/china/commune-by-the-great-wall/pekub" target="_blank">{{$t('header.commune')}}</a>
            <a href="https://esg.sohochina.com/#/index?l=en" target="_blank">ESG</a>
          </div>
        </transition>
      </div>
    </header>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import Cookie from 'js-cookie'
export default {
  name: "Header",
  data(){
    return{
      menuFlag:false,
      menuclickFlag:false
    }
  },

  mounted() {
    window.addEventListener("click", this.clickOther);
  },
  computed:{
    ...mapState({
      lang:state =>state.lang
    })
  },

  beforeDestroy() {  // 实例销毁之前对点击事件进行解绑
    window.removeEventListener('click', this.clickOther);

  },
  methods:{
    menuClick(){
      this.menuFlag = !this.menuFlag
    },
    clickOther(){

      this.menuFlag = false
    },
    imgClick(){
      this.$router.push("/")
    },

    changeZn(){
      Cookie.set('language','zh')
      this.$i18n.locale = 'zh'
      this.$router.push({path:'/media'})
    },

    changeEn(){
      Cookie.set('language','en')
      this.$i18n.locale = 'en'
      this.$router.push({path:'/media'})

    }

  }
}
</script>



<style lang="scss">

/* 头部模块 */

.header {
  background:#ffffff;
  width: 1200px;
  margin: 0 auto;
  height: 70px;
  position: relative;
  .hdNav ul {
    display: flex;
    height: 70px;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
  }
  .hdNav .logo img{
    width: 197px;
    height: auto;
    margin-left: 46px;
    margin-top: 5px;
  }
  & a{
    font-family: "Barlow-Regular";
    color: #404040;
    opacity: 0.8;

  }
  & span{
    font-family: "BarlowCondensed-Regular";
    color: #404040;
    letter-spacing: 1px;
  }
  .menu{
    overflow:hidden;
    position: absolute;
    width: 100%;
    height: 70px;
    line-height: 70px;
    //background: rgba(255,255,255,0.8);
    background: rgba(255,255,255,0.7);
    top:70px;
    left: 0;
    padding-left: 0px;
    box-sizing: border-box;
    z-index: 9999;
    & a{
      margin-left: 40px;
    }

  }

  & .menuImg{
    width:18px;
    height: 15px;
    margin-right: 6px;

  }
  & .y-span{
    margin:0 6px;
  }
  & .menupot{
    cursor: pointer;

  }
  & .menupot span{
    color:#424242;
    font-size: 16px;
  }
  & .li-cursior{
    cursor: pointer;
    display: flex;
  }

  & .active{
    color: #DC4227;
  }
  & .en-mobile{
    display: none;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: all 0.5s;
  padding-left: 0;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateX(10px);

}
/* 手机和平板端 */
@media(max-width:992px) {

  .header {
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    .hdNav .logo img{
      width: 129px;
      height: 44px;
      margin-left: 0;
    }
    .menu{
      display: flex;
      overflow:hidden;
      position: absolute;
      width: 100%;
      height: auto;
      line-height:1;
      flex-wrap: wrap;
      text-align: center;
      //background: rgba(255,255,255,0.8);
      background: rgba(255,255,255,0.7);
      top:60px;
      left: 0;
      padding-left: 0px;
      box-sizing: border-box;
      z-index: 9999;

      & a{
        height: 40px;
        line-height: 40px;
        width: 50%;
        display: block;
        margin-left:0px;
      }

    }

    & .menuImg{
      width:18px;
      height: 15px;
      margin-right: 6px;
    }
    & .y-span{
      margin:0 6px;
    }
    & .menupot{
      cursor: pointer;
    }
    & .en-pc{
      display: none;
    }
    & .en-mobile{
      display: block;
    }

  }

}
</style>
